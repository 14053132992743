@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


html, body {
  overflow-x: hidden;
  width: 100%;
}

.semi-container-to-right {
    width: 100%;
    margin-left: auto;

    @media (min-width:640px) {
        max-width: 640px;
    }

    @media (min-width:768px) {
        max-width: 90%;
    }

    @media (min-width:2000px) {
        max-width: 2000px;
        margin: auto;
    }
}
.youtube-player-placeholder {
    width: 100% !important;
}

.header-dense {

    nav {
        padding-top: 16px !important;
        padding-bottom: 16px !important;

        .logo {
            height: 100% !important;
            width: auto !important;
        }

        ul {
            li {
                a::after {
                    top: -1.4rem
                }
            }
        }
    }
}
div[modal-backdrop] {
    z-index: 10 !important;
}

.connect-wallet-button{
    color: white;
    background: linear-gradient(90deg, rgba(153,51,255,1) 0%, rgba(8,203,203,1) 100%);
}
.connect-wallet-button:hover{
    background: #0FFFFF;
}

.container{
    @media screen and (max-width: 1279px) {
        max-width: 100%;
    }
}

.fill-available {
    width: -webkit-fill-available; /* Chrome, Safari */
    width: -moz-available; /* Firefox */
    width: fill-available; /* Standard */
}
#anything-llm-embed-chat-container{
    .allm-mb-4{
        @media only screen and (max-width: 600px) {
            margin-bottom: 16px !important;
        }
    }
    .allm-mr-4{
        @media only screen and (max-width: 600px) {
            margin-right: 0.5rem !important;
        }
    }
} 